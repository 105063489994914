import React, { useEffect } from 'react';

const NotFound: React.FC = () => {
  useEffect(() => {
    window.location.href = '/app';
  }, []);
  return null;
};

export default NotFound;
